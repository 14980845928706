import apiConfig from '@/config/relysiaApi'

const walletID = '00000000-0000-0000-0000-000000000000'

export const getwalletBal = async () => {
  //wallet balance
  const walletBal = await apiConfig
    .get('/v1/metrics', {
      headers: {
        walletID,
      },
    })
    .then((res) => {
      let balInBsv =
        res?.data?.data?.data?.balance / 100000000
          ? res.data.data.data.balance / 100000000
          : 0
      return balInBsv
    })
    .catch((err) => {
      console.log('metrics error', err, err.response)
      if (
        err?.response?.data?.data?.msg?.includes('user utxos does not exists')
      ) {
        return 0
      }
    })
  return walletBal
}

export const walletSetup = async (name, authToken) => {
  try {
    const headers = {
      walletTitle: `lillbit-${name}`,
      authToken,
    }
    const walletResponse = await apiConfig.get('/v1/createWallet', {
      headers,
    })
    if (walletResponse && walletResponse.data && walletResponse.data.data) {
      const data = walletResponse.data.data
      return data
    } else {
      return { message: 'unable to create wallet', status: 'error' }
    }
  } catch (error) {
    console.log('error in wallet-setup', error.message)
    return { message: error, status: 'error' }
  }
}

export const getMnemonicFromApi = async () => {
  try {
    const headers = {
      walletID,
    }
    const resp = await apiConfig.get('/v1/mnemonic', {
      headers,
    })
    if (resp && resp.data && resp.data.data) {
      const data = resp.data.data.mnemonic
      return {
        error: '',
        message: 'success',
        data,
      }
    }
    return { error: '', message: 'success', data: {} }
  } catch (error) {
    console.log('error in getMnemonic', error.response)
    return { error: error.message, message: 'error', data: {} }
  }
}

export const getAddressFromApi = async (authToken) => {
  try {
    const headers = {
      walletID,
      authToken,
    }

    const resp = await apiConfig.get('/v1/address', {
      headers,
    })

    if (resp && resp.data && resp.data.data) {
      const data = resp.data.data
      return {
        error: false,
        message: 'success',
        data,
      }
    }
    return {
      error: true,
      message: 'error',
      paymail: null,
      address: null,
      data: {},
    }
  } catch (error) {
    return {
      error: true,
      paymail: null,
      address: null,
      message: 'error',
      data: {},
    }
  }
}

export const getHistory = async (nextToken) => {
  try {
    let url = '/v2/history'
    if (nextToken) {
      url = url + `?nextPageToken=${nextToken || ''}`
    }
    const resp = await apiConfig.get(url)
    if (resp && resp.data && resp.data.data) {
      const data = resp.data.data
      return {
        error: '',
        message: 'success',
        data,
      }
    }
    return { error: '', message: 'success', data: {} }
  } catch (error) {
    console.log('error in getHistory', error.message)
    return { error: error.message, message: 'error', data: {} }
  }
}

export const getBalance = async () => {
  try {
    const resp = await apiConfig.get('v2/balance')
    if (resp && resp.data && resp.data.data) {
      const data = resp.data.data

      const balance = data.totalBalance.balance || 0
      const bsv = data.coins[0].balance || 0
      const calculatedBsv = bsv === 0 ? 0 : (bsv / 100000000).toFixed(4)
      const calculatedBalance = Number.parseFloat(balance).toFixed(7)

      return {
        error: '',
        message: 'success',
        data: {
          balanceUsd: calculatedBalance,
          balanceBSV: calculatedBsv,
        },
      }
    }
    return {
      error: '',
      message: 'success',
      data: {
        balanceUsd: 0.0,
        balanceBSV: 0.0,
      },
    }
  } catch (error) {
    console.log(error)
    console.log('error in getHistory', error.message)
    return { error: error.message, message: 'error', data: {} }
  }
}

export const getCurrencyRate = async () => {
  const bsv = 1
  const headers = {
    satoshis: bsv.toString(),
    currency: 'USD',
  }
  try {
    const resp = await apiConfig.get('/v1/currencyConversion', {
      headers,
    })
    if (resp && resp.data && resp.data.data) {
      const rate = resp.data.data.balance
      return rate
    }
  } catch (er) {
    console.log('error in getCurrencyRate', er)
  }
  return null
}

export const sendPayment = async (dataArray, retryCount = 0) => {
  const maxRetries = 3

  try {
    const resp = await apiConfig.post('/v1/send', { dataArray }).then((r) => {
      return r.data.data.txIds[0]
    })
    return resp
  } catch (error) {
    if (retryCount < maxRetries) {
      retryCount++
      const resp = await sendPayment(dataArray, retryCount)
      return resp
    } else {
      return error?.response?.data?.data
    }
  }
}
